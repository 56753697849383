import { IOfferViewModel, OfferTypeValues } from '@model/dist/esm';
import { JobPostingJsonLd, NextSeo, NextSeoProps } from 'next-seo';
import { useRouter } from 'next/router';
import { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { getLanguageAlternates, getLocaleSuffix } from '../../lib/seo/locales';
import { useDefaultEstablishmentLogo } from '../ui/utils/image';
import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()
const baseUrl = publicRuntimeConfig.websiteBaseUrl;


interface Props {
    /** Path after locale, should start with '/' */
    pageKey: string,
    title?: string;
    description?: string;
    offer?: IOfferViewModel
}
interface RemovedProps {
}

type RestNextSeoProps = Omit<NextSeoProps, keyof (Props & RemovedProps)>
export type SeoProps = Props & RestNextSeoProps

const Seo: React.FC<SeoProps> = ({
    pageKey,
    title: pTitle,
    description: pDescription,
    offer,
    ...seoProps
}) => {

    const { t } = useTranslation();
    const tt = useCallback((itemKey: string) => {
        if (!itemKey) return null;
        const key = `${pageKey}.${itemKey}`
        const translation = t(`seo:${key}`)
        // si pas de traduction, pas de prop
        if (translation === key) return null;
        return translation;
    }, [t])


    const { locale, defaultLocale, locales, asPath } = useRouter();
    const localeSuffix = getLocaleSuffix(locale, defaultLocale)
    const languageAlternates = getLanguageAlternates(baseUrl, asPath, locale, defaultLocale, locales)

    const title = pTitle ?? tt(`title`)
    const description = pDescription ?? tt(`description`)

    const logo_url = useDefaultEstablishmentLogo(offer?.establishment?.logo)
    const logoImage = `${baseUrl}/images/opengraph/image_1.jpeg`;

    const images = offer?.establishment ? [{
        url: logoImage,
        alt: 'Teacher Tribe',
        width: 1200,
        height: 630,
    },{
        url: logo_url,
        alt: `${offer.establishment.name}`,
        width: 500,
        height: 500,
    }] : undefined;

    return (
        <NextSeo
            title={title}
            description={description}
            canonical={`${baseUrl}${localeSuffix}${asPath}`}
            languageAlternates={languageAlternates}
            {...seoProps}
            openGraph={{
                title: title,
                images: images,
            }}
        />
    )
}

export default Seo;
