import { useEffect, useState } from 'react'
import getConfig from 'next/config';

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig()
const { cdnEndpoint } = publicRuntimeConfig

const DEFAULT_ESTABLISHMENT_LOGO_URL = `${cdnEndpoint}/images/default/establishment-logo.png`
export const useDefaultEstablishmentLogo = (logoUrl) => {
    const [logo, setLogo] = useState(!logoUrl ? DEFAULT_ESTABLISHMENT_LOGO_URL : logoUrl)

    useEffect(() => {
        if (!['', undefined, null].includes(logoUrl)) {
            setLogo(logoUrl)
        }
    }, [logoUrl])

    return logo
}