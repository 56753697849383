import { Routes } from 'lib/routes';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { PropsWithChildren } from 'react';
import Button from '../button/Button';

interface Props {
    title: string;
    btnText: string;
}

const CreateAlertCTA: React.FC<Props> = ({ title, btnText }) => {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col items-center w-full px-4 py-10 mt-20 mb-10 bg-white rounded-lg space-y-7">
            <h3 className="text-2xl font-bold text-center">{title}</h3>
            <Link passHref href={Routes.JOIN_TEACHER_TRIBE}>
                <Button className="light">
                    {btnText}
                </Button>
            </Link>
        </div>
    )
}

export default CreateAlertCTA;